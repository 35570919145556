@font-face {
	font-family: "Roboto";
	src: url(../public/Roboto-BoldCondensed.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;600&display=swap");

:root {
	--border: 2px solid white;
	--borderRadius: 25px;
	--bgFirst: #aac0e2;
	--second-color: #5d7597;
	--linear-width-height: 79.375mm;
	--shadow: 8px 8px 12px 4px rgba(66, 68, 90, 0.4);
}
/************************************************** ogólne */
/************************************************** ogólne */
/************************************************** ogólne */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Quicksand", sans-serif;
	color: white;
	font-size: 16px;
}
html {
	scroll-behavior: smooth;
}
body {
	/* background: linear-gradient(45deg, rgb(76, 95, 148) 0%, rgb(122, 145, 179)35%, rgb(131, 161, 204) 50%, rgb(132, 157, 196) 65%, rgb(82, 74, 149) 100%); */
	background: var(--bgFirst);
	background-repeat: no-repeat;
	background-attachment: fixed;
	overflow-x: hidden;
}
/* body:before{
  content:"";
  background-image: url(./img/bg-worm.png);
  background-repeat:no-repeat;

  position: fixed;
  background-size: contain;
  top:10%;
  left:0;
  width:100%;
  height:100%;
  z-index:-1;
} */
input,
textarea,
button,
select,
a,
label,
label:focus {
	-webkit-tap-highlight-color: transparent;
}
section {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 80px 50px;
	overflow: hidden;
	scroll-behavior: unset;
}
button {
	background-color: rgba(255, 255, 255, 0);
	border: 2px solid white;
	border-radius: 30px;
	padding: 10px 20px;
	text-transform: uppercase;
	transition: 0.3s;
	cursor: pointer;
	outline: none;
}
button:hover {
	background-color: rgba(255, 255, 255, 1);
	color: var(--second-color);
}
input,
textarea {
	background-color: rgba(255, 255, 255, 0);
	border: 2px solid rgba(255, 255, 255, 0.5);
	border-radius: 30px;
	padding: 5px 10px;
	transition: 0.3s;
	outline: none;
}
textarea {
	border-radius: 15px;
	resize: none;
	cursor: auto;
}
textarea::-webkit-scrollbar {
	width: 12px;
}
textarea::-webkit-scrollbar-track {
	border-radius: 15px;
	margin: 5px;
}
input:focus,
textarea:focus {
	border: 2px solid white;
}
input:-webkit-autofill,
textarea:-webkit-autofill {
	transition: background-color 600000s 0s, color 600000s 0s;
}
label {
	font-size: 1.2rem;
	font-weight: bold;
}
img {
	object-fit: contain;
}
a {
	text-decoration: none;
}
h2 {
	font-size: 4rem;
}
h3 {
	font-size: 2rem;
}
.section-header {
	margin-bottom: 2rem;
	margin-top: 2rem;
	text-align: center;
}
p {
	font-size: 1.5rem;
}
li {
	list-style: none;
}
.glass {
	background-color: rgba(93, 117, 151, 0.2);
	/* background-color: rgba(255, 255, 255, 0.1); */
	backdrop-filter: blur(10px);
	border-radius: var(--borderRadius);
}
.shadow {
	box-shadow: var(--shadow);
}
.page {
	padding-top: 100px;
}
.highlight {
	color: rgb(134, 28, 28);
	font-weight: bold;
}
.fb,
.insta {
	font-size: inherit;
	font-weight: bold;
}
.fb {
	color: #3b5998;
}
.insta {
	background: linear-gradient(
		135deg,
		rgba(64, 93, 230, 1) 0%,
		rgba(88, 81, 219, 1) 10%,
		rgba(131, 58, 180, 1) 30%,
		rgba(193, 53, 132, 1) 45%,
		rgba(225, 48, 108, 1) 60%,
		rgba(253, 29, 29, 1) 72%,
		rgba(245, 96, 64, 1) 86%,
		rgba(252, 175, 69, 1) 95%,
		rgba(255, 220, 128, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
::-webkit-scrollbar {
	width: 16px;
}
::-webkit-scrollbar-track {
	background-color: #788aa5;
	margin: 2px;
}
::-webkit-scrollbar-thumb {
	border-radius: 15px;
	background: white;
}

/************************************************** urlop */
/************************************************** urlop */
/************************************************** urlop */
.urlop-container {
	position: fixed;
	height: 30px;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 5;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background-color: #dee7f5;
}
.urlop-container > p {
	text-align: center;
}

.urlop-modal-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100vw;
	height: 100vh;
	background-color: rgba(3, 6, 29, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.urlop-modal-window {
	padding: 50px;
	background-color: var(--bgFirst);
	border-radius: var(--borderRadius);
	border: var(--border);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
.urlop-modal-window > p,
.urlop-modal-window > button {
	font-size: 1.7rem;
}
@media (max-width: 365px) {
	.urlop-container > p {
		font-size: 1.2rem;
	}
	.urlop-modal-window {
		padding: 20px;
	}
}
@media (max-width: 450px) {
	.urlop-modal-window {
		padding: 20px;
	}
}
/************************************************** navigacja */
/************************************************** navigacja */
/************************************************** navigacja */

nav {
	width: 100vw;
	position: fixed;
	z-index: 5;
	/* ************************************************* tutaj zmiana urlop */
	/* top: 30px; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	padding: 3px 50px;
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(10px);
}
.nav-menu-desktop {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
}
.nav-menu-desktop > li > a {
	text-transform: uppercase;
	font-weight: bold;
	padding: 0 5px;
	font-size: 1.5rem;
	transition: color 0.3s;
}
.nav-menu-desktop > li > a:hover {
	border-bottom: 2px solid white;
}
.nav-menu-mobile {
	position: fixed;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 80px 20px 20px 30px;
	background-color: var(--second-color);
	/* backdrop-filter: blur(10px); */
}
.nav-menu-mobile > li > a {
	text-transform: uppercase;
	font-size: 2rem;
	color: white;
	font-weight: bold;
}

.nav-logo-wrap {
	height: 90%;
}
.nav-logo {
	height: 95%;
}
.hamburger,
.hamburger-x {
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	overflow: visible;
}
.bar {
	width: 40px;
	height: 4px;
	background-color: white;
	border-radius: 3px;
	transition: all 0.2s;
}
.hamburger-x .top-bar {
	transform: rotate(45deg);
	transform-origin: 15% 50%;
	width: 50px;
}
.hamburger-x .middle-bar {
	opacity: 0;
}
.hamburger-x .bottom-bar {
	transform: rotate(-45deg);
	transform-origin: 15% 50%;
	width: 50px;
}
/************************************************** toggleLang */
/************************************************** toggleLang */
/************************************************** toggleLang */
.ToggleLang {
	display: flex;
	gap: 5px;
}
.lang-button {
	background-position: center;
	background-size: cover;
	filter: grayscale(80%);
	width: 35px;
	height: 25px;
	transition: 0.25s;
}
.lang-button:hover {
	filter: grayscale(0%);
}
.pl-button {
	background-image: url("./img/pl-flag.jpg");
}
.en-button {
	background-image: url("./img/en-flag.jpg");
}

/************************************************** hero with modele */
/************************************************** hero with modele */
/************************************************** hero with modele */
.HeroModele {
	width: 100vw;
	height: 100vh;
	background: var(--second-color);
	backdrop-filter: blur(15px);
	display: flex;
	align-items: center;
	position: relative;
	z-index: 0;
	overflow-x: hidden;
}
.mod-content {
	width: 40vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5%;
	padding: 2% 0 2% 5%;
}
.mod-content-logo {
	width: 50%;
}
.mod-content > h2 {
	align-self: flex-start;
}
.mod-content-text > p {
	font-size: 1.6rem;
}
.mod-outer-carousel {
	overflow-x: hidden;
	position: relative;
	width: 60vw;
	height: 100%;
}
.mod-inner-carousel {
	display: flex;
	align-items: center;
	overflow-x: visible;
	width: 100%;
	height: 100%;
	margin-left: 1%;
}
.ultimate-mod-wrapper {
	flex: 0 0 100%;
	height: 100%;
	display: flex;
	gap: 1%;
	justify-content: center;
	align-items: center;
}
.pretty-wrapper {
	width: 50%;
	aspect-ratio: 7 / 9;
	border: var(--border);
	border-radius: 50%;
	position: absolute;
	margin: 60px 0 0 -100px;
	z-index: -1;
}
.mod-wrapper {
	width: 50%;
	aspect-ratio: 7 / 9;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	overflow-y: hidden;
}
.mod-wrapper > img {
	pointer-events: none;
	height: 75%;
	margin-left: 2%;
}
.mod-wrapper > img:first-child,
.mod-wrapper > img:nth-child(2) {
	margin-bottom: -50px;
}
.mod-info {
	position: absolute;
	overflow-x: visible;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	margin-bottom: 40%;
	margin-left: 20%;
	padding-right: 30%;
	width: 100%;
}
.mod-info > h2 {
	border-bottom: var(--border);
	overflow-x: visible;
	padding: 2% 0;
}
.mod-info-paragraph {
	text-align: end;
	width: 70%;
	padding: 2% 0;
}
.carousel-controls {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.carousel-prev,
.carousel-next {
	padding: 10%;
	opacity: 0.7;
	z-index: 3;
	cursor: pointer;
}
.carousel-prev:hover,
.carousel-next:hover {
	opacity: 1;
}
.disabled {
	opacity: 0;
	pointer-events: none;
	cursor: none;
}

/***************************************************************** nici i materiały section */
/***************************************************************** nici i materiały section */
/***************************************************************** nici i materiały section */
.NiciMaterialy {
	display: flex;
	flex-direction: column;
}
.nici-materialy-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.nici-materialy-item {
	width: 100%;
	padding: 20px;
	display: grid;
	gap: 20px;
	grid-template-columns: 20% 80%;
	grid-template-rows: auto;
	grid-template-areas:
		"header header"
		"img info";
}
.nici-materialy-item:nth-child(2) {
	grid-template-columns: 80% 20%;
	grid-template-rows: auto;
	grid-template-areas:
		"header header"
		"info img";
}
.nici-materialy-item:nth-child(2) > .nici-materialy-info {
	text-align: end;
}
.nici-materialy-header,
.nici-materialy-img,
.nici-materialy-info {
	width: 100%;
	height: 100%;
}
.nici-materialy-header {
	grid-area: header;
	text-align: center;
	padding: 10px;
}
.nici-materialy-img {
	grid-area: img;
	border-radius: var(--borderRadius);
}
.nici-materialy-info {
	grid-area: info;
	padding: 20px 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
/********************************************************************************** ProjectOrFb */
/********************************************************************************** ProjectOrFb */
/********************************************************************************** ProjectOrFb */
.ProjectOrFb {
	width: 100%;
	justify-content: center;
	align-items: stretch;
	gap: 4%;
}
.proj-fb-etsy-item {
	width: 46%;
	padding: 30px 10px;
	border-radius: var(--borderRadius);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 30px;
	transition: all 0.3s;
}
.proj-fb-etsy-item:hover {
	box-shadow: var(--shadow);
	scale: 1.005;
}
.proj-fb-etsy-item > h3 {
	text-align: center;
}
.proj-fb-etsy-item > a {
	width: 90%;
}
.proj-fb-etsy-item:nth-child(2) {
	background-color: rgba(255, 255, 255, 0.6);
}
.proj-fb-etsy-item:nth-child(2) > h3 {
	color: var(--second-color);
}
.proj-fb-etsy-item:nth-child(2) > a > .proj-fb-etsy-button {
	color: var(--second-color);
	border-color: var(--second-color);
}
.proj-fb-etsy-item:nth-child(1) {
	background-color: var(--second-color);
}
.proj-fb-etsy-button {
	width: 100%;
	font-weight: bold;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.proj-fb-etsy-button > svg {
	height: 1.8rem;
}
.proj-svg {
	width: 35%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.proj-svg > svg {
	width: 100%;
}
/************************************************************************** BagsToBuy */
/************************************************************************** BagsToBuy */
/************************************************************************** BagsToBuy */
.BagsToBuy {
	padding: 50px;
}
.BagsToBuy p {
	text-align: center;
	margin-bottom: 50px;
}
.bags-to-buy-container {
	display: flex;
	flex-wrap: wrap;
	gap: 4%;
}
.bags-to-buy-item {
	width: 22%;
	border-radius: var(--borderRadius);
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 10px;
	position: relative;
}
.bags-to-buy-item a button {
	width: 100%;
}
.bags-to-buy-item img {
	width: 100%;
	aspect-ratio: 1/1;
	object-fit: cover;
	object-position: center;
	border-radius: 12px;
}
.bags-to-buy-item-price {
	position: absolute;
	top: -20px;
	left: -22px;
	width: 30%;
	background-color: rgba(0, 0, 0, 0.2);
	aspect-ratio: 1/1;
	border-radius: 200px;
	border: var(--border);
	font-size: 2.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.bags-to-buy-item-price:before {
	content: "";
	position: absolute;
	width: 100%;
	padding: 5px;
	aspect-ratio: 1/1;
	border-radius: 200px;
	border: var(--border);
}
@media (min-width: 669px) and (max-width: 1050px) {
	.bags-to-buy-item a button {
		padding: 3px 10px;
	}
  .bags-to-buy-item-price {
		top: -5px;
		left: -2px;
		font-size: 1.8rem;
	}
}
@media (max-width: 669px) {
	.BagsToBuy {
		padding-left: 5px;
		padding-right: 5px;
	}
	.bags-to-buy-item {
		width: 48%;
	}
	.bags-to-buy-item-price {
		top: -5px;
		left: -2px;
		font-size: 1.6rem;
	}
}
/************************************************************************** review */
/************************************************************************** review */
/************************************************************************** review */
.Reviews {
	width: 100vw;
	overflow: hidden;
}
.review-header {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 15px;
	padding: 30px 0;
}
.review-header > p,
.review-header-stars > p {
	font-size: 2rem;
}
.review-header-stars {
	display: flex;
	justify-content: baseline;
	gap: 5px;
}
.tanslate-info {
	width: 100vw;
	text-align: center;
}
.comments {
	width: fit-content;
	display: flex;
	/* gap:50px; */
	align-items: stretch;
	overflow: hidden;
	/* padding-left: 50px; */
}
.comment {
	width: 42vw;
	padding: 30px;
	margin-left: 50px;
}
.uni-controls {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: flex-end;
	align-items: center;
	gap: 30px;
	padding-bottom: 20px;
}
.uni-controls > div {
	width: 60px;
	height: 60px;
	cursor: pointer;
	transition: all 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
}
.uni-controls > div > img {
	height: 70%;
}
@media (max-width: 669px) {
	.review-header {
		flex-direction: column;
		align-items: flex-start;
		padding: 0 10px;
	}

	.comment {
		width: 80vw;
		margin-left: 20px;
		margin-top: 10px;
	}
	.tanslate-info {
		text-align: left;
	}
}
/************************************************************************** projektuj */
/************************************************************************** projektuj */
/************************************************************************** projektuj */
.Projektuj {
	width: 100%;
}
.ProjektujInfo {
	text-align: center;
}
.ProjektujInfo-instruction {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 20px;
}
.ProjektujInfo-instruction > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
}
.ProjektujInfo-instruction > div:first-child {
	width: 50%;
}
.ProjektujInfo-instruction > div:nth-child(2) {
	width: 10%;
}
.ProjektujInfo-instruction > div:nth-child(3) {
	margin-top: 20px;
	padding: 10px 20px;
}
.ProjektujInfo-social-media > .social-media-contact-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.ProjektujInfo-social-media > p {
	margin-bottom: 10px;
}
/************************************************************************** linear3 */
/************************************************************************** linear3 */
/************************************************************************** linear3 */
.LinearModelComponent {
	width: 100%;
}
.bag-menu {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 30px;
	padding-bottom: 30px;
}
.bag-item {
	width: 280px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	padding: 20px 5px;
}
.bag-item-name {
	font-size: 2rem;
	font-family: "Roboto";
	text-align: center;
}
.linear-item {
	position: relative;
	width: 100%;
}

/************************************************************************** projektuj materiały */
/************************************************************************** projektuj materiały */
/************************************************************************** projektuj materiały */
.FabricsComponent {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.material-type {
	font-size: 2rem;
	display: inline;
	margin-bottom: 5px;
}
.wodoodporne-wrapper,
.welur-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5%;
	padding: 20px 0;
	margin-bottom: 30px;
}
.material-wrapper {
	max-width: 200px;
	margin: 3vw 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 15px;
	position: relative;
}
.material-name {
	background-color: var(--second-color);
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 3px 0;
	border-radius: 0 0 15px 15px;
}
.material-wrapper > img {
	width: 100%;
	cursor: zoom-in;
	border-radius: 15px 15px 0 0;
}
.scrap {
	position: absolute;
	font-size: 0.9rem;
	background-color: rgb(134, 28, 28);
	width: 100%;
	border-radius: 15px 15px 0 0;
	text-align: center;
	padding: 2px 0;
	cursor: help;
}
@media (max-width: 445px) {
	.material-wrapper {
		width: 45%;
	}
}
/************************************************** ModalForLinear3 */
/************************************************** ModalForLinear3 */
/************************************************** ModalForLinear3 */
.example-container {
	width: 90%;
	padding: 0 1%;
	background: var(--bgFirst);
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.model-example-header {
	width: 100%;
	position: sticky;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--bgFirst);
	z-index: 1;
}
.example-close {
	cursor: pointer;
}
.all-models-example {
	width: 90%;
	display: flex;
	justify-content: center;
	gap: 12px;
}
.linear-example {
	width: 100%;
	cursor: pointer;
}
.active-example {
	box-shadow: var(--shadow);
}
.model-example-section {
	width: 100%;
	padding: 0px 50px 30px 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.first-half,
.second-half {
	width: 50%;
	display: flex;
	justify-content: center;
	padding: 20px;
}
.first-half {
	border-right: var(--border);
}
.model-example-size-svg {
	position: relative;
	height: 30vw;
}
.size-linear {
	position: absolute;
	width: 80%;
}
.size-text {
	display: flex;
}
.model-text {
	font-size: 2.1em;
	/* font-weight: 600; */
}
.model-text-color {
	color: var(--second-color);
	margin-right: 10px;
}
.features-header {
	text-transform: uppercase;
	padding: 0 10px;
}
.model-example-features {
	width: 100%;
	flex-wrap: wrap;
	gap: 0;
}
.features-item {
	width: 30%;
	aspect-ratio: 1/1;
	background-color: var(--second-color);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.features-item:nth-last-child(1) {
	background-color: #34465f;
	cursor: pointer;
	transition: all 0.3s;
	flex-direction: column;
	gap: 30px;
}
.features-item:nth-last-child(1):hover {
	background-color: var(--second-color);
}
.features-actual-img {
	width: 100%;
}
.features-text {
	text-align: center;
	font-size: 1.7rem;
	padding: 0 20px;
}

.model-example-variants {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	gap: 20px;
}
.variant {
	max-width: 350px;
	position: relative;
}
.variant-img {
	width: 100%;
	border: var(--border);
}
.variant-price,
.variant-name {
	background-color: var(--second-color);
	display: inline;
	padding: 4px 10px;
	position: absolute;
	right: -10px;
}
.variant-name {
	top: 10px;
}
.variant-price {
	top: 50px;
}
@media (max-width: 669px) {
	.example-container {
		width: 100vw;
		overflow-x: hidden;
	}
	.model-example-header > h2,
	.features-header {
		font-weight: normal;
	}
	.features-header {
		border-bottom: 2px solid white;
	}
	.all-models-example {
		gap: 0;
		width: 190vw;
		align-self: flex-start;
	}
	.linear-example {
		padding: 0 5px;
	}
	.model-example-section {
		flex-direction: column;
		padding: 20px 10px 30px 10px;
	}
	.first-half,
	.second-half {
		width: 100%;
		padding: 5px;
	}
	.first-half {
		border-right: none;
	}
	.model-example-size-svg {
		width: 90vw;
		height: 70vw;
	}
	.model-example-features {
		flex-direction: row;
	}
	.features-item {
		width: 50%;
	}
	.features-item:nth-child(1) {
		order: 1;
	}
	.features-item:nth-child(2) {
		order: 2;
	}
	.features-item:nth-child(3) {
		order: 4;
	}
	.features-item:nth-child(4) {
		order: 3;
	}
	.features-item:nth-child(5) {
		order: 5;
	}
	.features-item:nth-child(6) {
		order: 6;
	}
	.features-text {
		font-size: 1.2rem;
	}
	.arrow-to-perelki {
		width: 40px;
	}
	.features-item:nth-last-child(1) {
		gap: 2px;
	}
}
/************************************************** fabric modal */
/************************************************** fabric modal */
/************************************************** fabric modal */

.FabricModal,
.GalleryModal,
.ModalForLinear3 {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100vw;
	height: 100vh;
	background-color: rgba(3, 6, 29, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.fabric-modal-img,
.gallery-modal-img {
	max-height: 90vh;
	overflow: hidden;
	position: relative;
}
.gallery-modal-img {
	display: flex;
	justify-content: center;
	width: 90%;
}
.fabric-modal-img > img,
.gallery-modal-img > img {
	height: 100%;
}
.fabric-modal-name {
	position: absolute;
	font-size: 2.7rem;
	left: 0;
	padding: 5px;
}
.controls {
	position: absolute;
	width: auto;
	width: 80%;
	display: flex;
	justify-content: space-between;
	z-index: inherit;
}
.prev,
.next {
	padding: 30px 20px;
	opacity: 0.7;
	cursor: pointer;
}
.prev {
	left: 10vw;
}
.next {
	right: 10vw;
}
.next:hover,
.prev:hover {
	opacity: 1;
	transition: 0.2s;
}
.gallery-top-element {
	position: absolute;
	width: 100%;
	top: 0px;
	z-index: 2;
	display: flex;
	justify-content: space-between;
}
.gallery-modal-name {
	font-size: 2.7rem;
}
.close {
	cursor: pointer;
}
.close,
.gallery-modal-name {
	padding: 10px;
}
/***************************************************************** HowItWorks */
/***************************************************************** HowItWorks */
/***************************************************************** HowItWorks */
/* .HowItWorks{
  display: flex;
  flex-direction: column;
}
.how-it-works-wrapper{
  width:100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position:relative;
}
.how-it-works-wrapper>div{
  width:50%;
  padding:30px 20px;
  display: flex;
  z-index:2;
}
.how-it-works-wrapper>div:nth-child(even){
  justify-content: flex-start;
}
.how-it-works-wrapper>div:nth-child(odd){
  justify-content: flex-end;
}
.how-it-works-wrapper>div:first-child>p,
.how-it-works-wrapper>div:nth-child(5)>p,
.how-it-works-wrapper>div:nth-child(9)>p{
  text-align:end;
}
.step>p{
  font-size: 1.6rem;
  border-radius: var(--borderRadius);
  padding:30px 50px;
} */
/***************************************************************** HowItWorks2 */
/***************************************************************** HowItWorks2 */
/***************************************************************** HowItWorks2 */
.HowItWorks {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.how-it-works-wrapper {
	display: flex;
	flex-direction: column;
	width: 80%;
	gap: 10px;
	position: relative;
	overflow-x: visible;
}
.step {
	width: 100%;
	display: flex;
	position: relative;
}
.step:nth-child(even) {
	justify-content: flex-end;
}
.step > p {
	width: 50%;
	padding: 20px;
	/* line-height: 2.3rem; */
	z-index: 2;
}
.step1 > p:before,
.step2 > p:before,
.step3 > p:before,
.step4 > p:before {
	content: "";
	width: 50%;
	height: 60%;
	position: absolute;
	top: 50%;
	border-top: var(--border);
}
.step1 > p:before,
.step3 > p:before {
	border-right: var(--border);
	left: 100%;
	border-radius: 0 60px 0 0;
}
.step2 > p:before,
.step4 > p:before {
	border-left: var(--border);
	right: 100%;
	border-radius: 60px 0 0 0;
}
@media (max-width: 669px) {
	.how-it-works-wrapper {
		width: 100%;
		gap: 30px;
	}
	.step > p {
		width: 80%;
		padding: 20px;
	}
	.step1 > p:before,
	.step2 > p:before,
	.step3 > p:before,
	.step4 > p:before {
		content: "";
		width: 15%;
		height: 90%;
	}
}
/***************************************************************** Core mętliki i inne */
/***************************************************************** Core mętliki i inne */
/***************************************************************** Core mętliki i inne */
.Core {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.core-wrapper {
	display: flex;
	align-items: stretch;
	justify-content: space-around;
}
.core-item {
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
	padding: 20px 0;
}
.core-imgs {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.core-imgs > svg:nth-child(2) {
	position: absolute;
}
.core-item > h2 {
	font-size: 2.1rem;
	width: 100%;
	text-align: center;
	font-weight: normal;
}
.core-about {
	text-align: center;
	padding: 0 10px;
}
.fill > .core-imgs > svg > g > path {
	fill: transparent;
	transition: 1s;
}
.fill:hover > .core-imgs > svg > g > path {
	fill: #fff;
	transition: 1s;
}
.out > .core-imgs > svg > g > path {
	left: 0;
	right: 0;
	transition: 1s;
}
.out > .core-imgs > svg {
	overflow: visible;
}
.out:hover > .core-imgs > svg > g > path {
	transition: 1s;
}
.out:hover > .core-imgs > svg > g > path:nth-child(1) {
	transform: translate(5px, -5px);
}
.out:hover > .core-imgs > svg > g > path:nth-child(2) {
	transform: translate(-10px, 0px);
}
.sprinkle > .core-imgs {
	transition: 1s;
}
.sprinkle > .core-imgs::before {
	content: "";
	position: absolute;
	z-index: 2;
	width: 3px;
	height: 5px;
	opacity: 0;
	transition: 1s;
	transform-origin: 50px;
	border-radius: 50px;
	box-shadow: -22px -70px white, -39px -54px white, -9px -41px white,
		4px -61px white, 28px -74px white, 38px -47px white, -37px -26px white,
		-49px -11px white, 45px -29px white, 1px -80px white, -22px -70px 4px white,
		-39px -54px 4px white, -9px -41px 4px white, 4px -61px 4px white,
		28px -74px 4px white, 38px -47px 4px white, -37px -26px 4px white,
		-49px -11px 4px white, 45px -29px 4px white, 1px -80px 4px white,
		-22px -70px 6px white, -39px -54px 6px white, -9px -41px 6px white,
		4px -61px 6px white, 28px -74px 6px white, 38px -47px 6px white,
		-37px -26px 6px white, -49px -11px 6px white, 45px -29px 6px white,
		1px -80px 6px white;
}
.sprinkle:hover > .core-imgs:before {
	opacity: 1;
	transition: 1s;
}
/***************************************************************** ContactUsWrapper */
/***************************************************************** ContactUsWrapper */
/***************************************************************** ContactUsWrapper */
.ContactUs {
	flex-direction: column;
}
.ContactUsWrapper {
	width: 80%;
	min-height: 530px;
	padding: 30px;
	display: flex;
	gap: 40px;
}
.ContactUs-text {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.social-media-contact-wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.social-media-contact {
	display: flex;
	gap: 5px;
	transition: 0.3s;
}
.social-media-contact > svg {
	min-width: 6mm;
	min-height: 6mm;
}
.social-media-contact-text,
.social-media-contact > p {
	font-size: 1.1rem;
}
.social-media-contact-wrapper {
	padding-top: 5px;
}
.social-media-contact:hover {
	opacity: 0.7;
}
.ContactUs-form {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.ContactUs-form > label {
	margin-left: 5px;
	margin-bottom: 5px;
}
.ContactUs-form > input,
.ContactUs-form > textarea {
	margin-bottom: 20px;
	font-size: 1.2rem;
	width: 100%;
}
.ContactUs-form > textarea {
	height: 200px;
}
.ContactUs-form > button {
	align-self: center;
	width: 40%;
}
.ContactUs-thanks {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}
.ContactUs-thanks > p {
	text-align: center;
}

/***************************************************************** footer */
/***************************************************************** footer */
/***************************************************************** footer */
.Footer {
	background-color: rgba(60, 89, 133, 0.5);
	backdrop-filter: blur(20px);
	padding: 50px 50px 100px 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;
	width: 100vw;
}
.social-media-wrapper > a > svg {
	width: 50px;
	margin: 0 20px;
}
.social-media-wrapper > a:hover svg path {
	fill: var(--bgFirst);
	transition: 0.3s;
}
/***************************************************************** HopUp */
/***************************************************************** HopUp */
/***************************************************************** HopUp */
.HopUp {
	width: 70px;
	height: 70px;
	background-color: #425978;
	position: fixed;
	left: 10px;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 3;
}
.HopUp > p {
	text-transform: uppercase;
	font-size: 1rem;
	text-align: center;
}
/***************************************************************** uszyte perelki */
/***************************************************************** uszyte perelki */
/***************************************************************** uszyte perelki */
.UszytePerelki {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.uszyte-perelki-wrapper {
	width: 90%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 15px;
}
.uszyte-perelki-item {
	width: 100%;
	cursor: zoom-in;
}
.uszyte-perelki-item > img {
	aspect-ratio: 1/1;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.UszytePerelki > p {
	width: 90%;
}
.filtry {
	width: 90%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	padding-top: 20px;
}
.show-me {
	font-size: 2rem;
}
.PerelkiFiltred {
	padding-top: 30px;
}
.Talizman {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.Talizman > img {
	width: 30%;
}
/***************************************************************** media queries */
/***************************************************************** media queries */
/***************************************************************** media queries */
@media (max-width: 669px) {
	body {
		overflow-x: hidden;
		position: relative;
		width: 100vw;
		height: 100vh;
	}
	::-webkit-scrollbar {
		display: none;
	}
	* {
		font-size: 14px;
	}
	h2 {
		font-size: 3rem;
	}
	section {
		padding: 50px 10px;
	}
	nav {
		padding: 0 20px;
		justify-content: center;
		height: 60px;
	}
	.hamburger,
	.hamburger-x {
		position: absolute;
		right: 20px;
	}
	.ToggleLang {
		position: absolute;
		left: 20px;
	}
	button {
		padding: 3px 10px;
		border-width: 1px;
	}
	.bag-item-info {
		border-width: 2px;
		font-weight: bold;
	}
	.fabric-modal-img,
	.gallery-modal-img {
		width: 90%;
		height: 90%;
	}
	.fabric-modal-img > img,
	.gallery-modal-img > img {
		width: 100%;
	}
	.controls {
		width: 90%;
	}
	.next,
	.prev {
		background-color: rgba(0, 0, 0, 0.2);
		padding: 30px 5px;
	}
	.HeroModele {
		flex-direction: column;
		justify-content: space-around;
		padding: 50px 15px 15px 15px;
		height: auto;
	}
	.mod-content {
		width: 100vw;
		padding: inherit;
		gap: 2vh;
	}
	.mod-content-logo {
		display: none;
	}
	.mod-outer-carousel {
		width: 100vw;
	}
	.mod-inner-carousel {
		height: auto;
		margin-bottom: 10%;
	}
	.pretty-wrapper {
		width: 70%;
		margin: 30px 0 0 -60px;
	}
	.mod-wrapper {
		width: 70%;
	}
	.mod-wrapper > img:first-child,
	.mod-wrapper > img:nth-child(2) {
		margin-bottom: -100px;
	}
	.mod-wrapper > img {
		height: 80%;
		padding-bottom: 20%;
	}
	.mod-info {
		padding-right: 20%;
	}
	.carousel-prev,
	.carousel-next {
		padding: 20px 0;
	}
	.carousel-prev > img,
	.carousel-next > img {
		width: 80%;
	}
	.core-wrapper {
		flex-direction: column;
		width: 95%;
		gap: 5vh;
	}
	.core-item {
		width: 100%;
		aspect-ratio: auto;
	}
	.core-item > img {
		padding: 20px 0;
	}
	.resume-copy {
		width: 100vw;
		padding: 10px 20px;
		flex-direction: column;
		align-items: flex-start;
		gap: 2px;
	}
	.resume-copy > p {
		width: 80%;
	}
	.resume-copy > button {
		width: 40%;
	}
	.resume-copy > p {
		font-size: 1.4rem;
	}
	.resume-copy > button {
		font-size: 1.2rem;
		padding: 5px 10px;
	}
	.uszyte-perelki-wrapper {
		width: 100%;
	}
	.UszytePerelki > p,
	.filtry {
		width: 100%;
	}
	.filtry button {
		padding: 5px 10px;
		font-size: 1.3rem;
	}
	.proj-radio-label {
		padding: 15px 20px;
	}
	.projektuj-wrapper {
		width: 98vw;
	}
	.nici-materialy-item,
	.nici-materialy-item:nth-child(2) {
		grid-template-columns: 100%;
		grid-template-rows: auto;
		grid-template-areas:
			"img"
			"header"
			"info";
		gap: 10px;
		padding: 20px 0;
	}
	.nici-materialy-info > p {
		text-align: center;
	}
	.ContactUsWrapper {
		width: 100%;
		flex-direction: column;
		gap: 0;
		padding: 10px;
	}
	.ContactUs-text {
		padding-bottom: 20px;
		border-bottom: var(--border);
		gap: 30px;
	}

	.ContactUs-form,
	.ContactUs-thanks {
		padding-top: 20px;
	}
	.ContactUs-text,
	.ContactUs-form,
	.ContactUs-thanks {
		width: 100%;
	}
	.ProjektujInfo-instruction > div:first-child {
		width: 80%;
	}
	.ProjektujInfo-instruction > div:nth-child(2) {
		width: 20%;
	}
	.ProjektujInfo-instruction > div:nth-child(3) {
		width: 100%;
		padding: 10px;
	}
	.ProjektujInfo-social-media > .social-media-contact-wrapper {
		grid-template-columns: 1fr;
	}
}
@media (max-width: 390px) {
	.proj-radio-label {
		font-size: 1.3rem;
	}
}
@media (max-width: 922px) {
	.ProjectOrFb {
		flex-direction: column;
		justify-content: center;
		gap: 30px;
	}
	.proj-fb-etsy-item {
		width: 100%;
	}

	.full-path {
		display: none;
	}
	.core-wrapper {
		width: 100%;
	}
	.linear-model-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		aspect-ratio: unset;
		height: auto;
		width: 100%;
	}
	.linear-model-full {
		width: 100%;
		height: 50vh;
	}
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	/********************************************* zamiast margin 0 mozna umiescic thumb zjęc przydkladowych */
	.linear-model-info {
		margin: 0 0 50px 0;
	}
	.uszyte-perelki-wrapper {
		grid-gap: 5px;
	}
	.uszyte-perelki-item > img {
		box-shadow: none;
	}
}
@media (min-width: 670px) and (max-width: 922px) {
	* {
		font-size: 12px;
	}
	.nav-link-items-wrap > li > a {
		font-size: 2rem;
	}
	.fabric-modal-img {
		max-width: 90vw;
	}
	.HeroModele {
		height: calc(50vw + 100px);
	}
	.mod-content-logo {
		align-self: flex-end;
	}
	.mod-wrapper > img {
		max-width: 200px;
	}
	.mod-wrapper > h2 {
		font-size: 4rem;
		margin: -40% 0 0 20%;
	}
	.mod-wrapper > p {
		width: 150px;
		margin: 20% 0 0 60%;
	}
}
